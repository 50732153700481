@import "../../styles/variables";
@import "../../styles/mixins";

.advantage {
    background-color: #000000;
    text-align: center;

    p {
        font-size: 20px;
        margin: 15px auto;
        max-width: 700px;
        color: #a3a3a3;
    }

    h2 {
        color: #fff;
        font-weight: 700;
        font-size: 45px;

        span {
            color: $cOne;
        }
    }

    &__sub{
        font-size: 1.15rem!important;
        max-width: 75%!important;
    }

    &__box {
        position: relative;

        &-img {
            display: inline-flex;
            width: 200px;
            height: 200px;
            line-height: 200px;
            margin: 0 auto;
            border-radius: 50%;
            justify-content: center;
            z-index: 2;
            text-align: center;
            border-style: solid;
            border-width: 0px 0px 0px 0px;
            box-shadow: 0px 0px 0px 12px #efe6e6 inset;

            img {
                object-position: left;
                object-fit: cover;
                width: 100%;
                opacity: 0.5;
                border-radius: 50%;
                height: auto;
            }
        }

        span {
            position: absolute;
            top: 50%;
            left: 15px;
            right: 15px;
            z-index: 2;
            font-size: 30px;
            color: #ffffff;
            font-weight: 600;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            font-family: Sans-serif;
            font-size: 70px;
            letter-spacing: 4px;
            color: rgba(0, 0, 0, 0);
            -webkit-text-stroke-color: #d5cccc;
            -webkit-text-stroke-width: 3px;
        }

       
    }

    @include mq("tablet") {
        h2 {
            font-size: 35px;
        }
        p {
            font-size: 17px;
            margin: 15px auto;
            max-width: 700px;
            color: #a3a3a3;
        }

        &__sub{
            font-size: 1.2rem!important;
            max-width: 75%!important;
        }
    }
}
