@import "./variables";

.button {
    color: #fff;
    background-color: transparent;
    border: 2px solid $cOne;
    margin-top: 25px;
    padding: 1em 2.5em;
    
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    letter-spacing: 1px;

    &:hover,
    &:focus {
        color: #fff;
        outline: 0;
    }
}

.button-fourth {
    border-color: $cOne;
    background: {
        image: linear-gradient(45deg, $cOne 50%, transparent 50%);
        position: 100%;
        size: 400%;
    }
    transition: background 300ms ease-in-out;

    &:hover {
        background-position: 0;
    }
}
