@import "../../styles/variables";
@import "../../styles/mixins";

.partnerssection {
    padding-bottom: 80px;
    background-color: #000;

    h2 {
        color: #fff;
        text-align: center;
    }

    &__box {
        padding: 20px !important;
        border: 1px solid rgba(255, 255, 255, 0.128);
        display: flex;
        height: 200px;
        max-height: 200px;
        align-items: center;
        justify-content: center;
        transition: 0.5s;

        img {
            filter: contrast(0.5);
            width: 100%;
            height: auto;
            transition: 0.5s;
        }

        &:hover {
            background-color: #d7deee2e;

            img {
                filter: none;
            }
        }
    }

    @include mq("tablet") {
        &__box {
            padding: 55px !important;
            height: 150px;
            max-height: 150px;
            margin-bottom: 20px;
        }
    }
}
