@import "../../styles/variables";
@import "../../styles/mixins";

.my-form {
    margin-top: 30px;

    &-info {
        margin-bottom: 40px;
        display: flex;
        color: #fff;

        svg {
            color: $cOne;
            font-size: 40px;
        }

        h5 {
            font-size: 25px;
        }

        span {
            font-size: 15px;
        }
    }

    &-right {
        padding: 30px 30px 0 30px;
        background-color: #111111;
    }

    .MuiInputLabel-root {
        color: white !important;
    }

    .Mui-focused,
    .MuiOutlinedInput-input,
    .MuiInput-input {
        color: #fff !important;
    }
}
