@import "../../styles/variables";
@import "../../styles/mixins";

.productions {
    background-color: #171717;
    &__header {
        margin-top: -80px;
        height: 708px;

        .productions__overlay {
            padding: 150px 0;
            background: #0116276b;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h4 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }

            h5 {
                margin-bottom: 0;
                color: #fff;
            }
        }
        .swiper_img1 {
            background-image: url("../../resources/img/Productions/Полотно.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .swiper_img2 {
            background-image: url("../../resources/img/Productions/экспериментальный.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .swiper_img3 {
            background-image: url("../../resources/img/Productions/Печатный.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .swiper_img4 {
            background-image: url("../../resources/img/Productions/Швейный.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .swiper-button-prev {
        background-image: url("../../resources/img/Productions/left-arrow.png")!important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        background-image: url("../../resources/img/Productions/right-arrow.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 50px;
        height: 50px;
        transition: all 0.2s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }

        &:after {
            display: none;
        }
    }

    &__container {
       
        padding: 80px 0;

        h3 {
            text-align: center;
            color: #fff;
            font-weight: 600;
        }

        p {
            padding: 35px 0;
            color: rgba(255, 255, 255, 0.77);
        }
    }
}
