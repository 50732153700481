@import "../../styles/variables";
@import "../../styles/mixins";

.svgmap {
    padding-bottom: 80px;

    h2 {
        text-align: center;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
        display: table;
        margin: 0 auto 15px;
    }

    h5 {
        margin: 0 auto 10px;
        display: table;
        color: #fff;
        cursor: pointer;
    }
    #myWindow {
        margin-top: 20px;
        overflow: hidden;
        margin-left: -700px;
        background: transparent;

        .Russian,
        .ukraine,
        .belarus,
        .kyrgyzstan,
        .germany,
        .turkey {
            transition: 0.3s;
            fill: #cdcdcd;
            &:hover {
                fill: #ce9e51;
            }
        }
    }

    .russian__hover,
    .ukraine__hover,
    .belarus__hover,
    .kyrgyzstan__hover,
    .turkey__hover,
    .germany__hover {
        transition: 0.3s;
        fill: #ce9e51;
    }

    @include mq("desktop-small") {
        #myWindow {
            margin-left: -880px;
        }
    }

    @include mq("tablet-mid") {
        #myWindow {
            margin-left: -1080px;
        }
    }

    @include mq("tablet") {
        #myWindow {
            margin-left: -1000px;
            overflow: scroll;
        }
    }

    @include mq("phone-wide") {
        h2 {
            text-align: center;
            font-size: 22px !important;
        }
    }
}
