@import "../../styles/variables";
@import "../../styles/mixins";

.products {
    padding-top: 0;
    overflow: hidden;

    &__header {
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 708px;
        animation: animateproduct 12s ease-in-out infinite;

        .products__overlay {
            margin-top: 80px;
            background: #0116276b;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }

            p {
                margin-bottom: 0;
                color: #fff;
            }
        }
    }

    h3 {
        margin-top: 50px;
        font-weight: 600;
        text-align: center;
    }

    .MuiButtonBase-root {
        color: #000;
        font-family: "Mons";
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
    .css-1q2h7u5.Mui-selected,
    .MuiTab-root .MuiTab-textColorPrimary {
        color: #000;
        font-family: "Mons";
    }

    .MuiTabs-indicator {
        background-color: $cOne;
    }

    img {
        margin-bottom: 20px;
        width: 100%;
        object-fit: cover;
        height: auto;
        cursor: pointer;
    }

    @keyframes animateproduct {
        100%,
        0%,
        18% {
            background-size: 100%;
            background-image: url("../../resources/img/ProductsPage/collapse/Collages1.jpg");
        }
        33%,
        48% {
            background-size: 115%;
            background-image: url("../../resources/img/ProductsPage/collapse/Collages2.jpg");
        }
        63%,
        78% {
            background-size: 130%;
            background-image: url("../../resources/img/ProductsPage/collapse/Collages3.jpg");
        }
    }

    @include mq("tablet-mid") {
        @keyframes animateproduct {
            100%,
            0%,
            18% {
                background-size: 100%;
                background-image: url("../../resources/img/ProductsPage/collapse/Collages1.jpg");
            }
            33%,
            48% {
                background-size: 110%;
                background-image: url("../../resources/img/ProductsPage/collapse/Collages2.jpg");
            }
            63%,
            78% {
                background-size: 120%;
                background-image: url("../../resources/img/ProductsPage/collapse/Collages3.jpg");
            }
        }
    }
}
