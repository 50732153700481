@import "../../styles/variables";
@import "../../styles/mixins";

.contacts {
    padding-top: 0;
    overflow: hidden;

    &__header {
        background-image: url("../../resources/img/Contacts/headphone.jpg");
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: 708px;

        .contacts__overlay {
            margin-top: 80px;
            background: #0116276b;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 80px;
                font-weight: 700;
                color: #fff;
            }

            span {
                color: #fff;
            }
        }
    }

    &__contact {
        background-attachment: fixed;
        background-image: url("../../resources/img/Contacts/contact-bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &-overlay {
            padding: 120px 0;
            background-color: #979797d3;
        }
    }

    .contacts-form-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.694);

        .form-info-inner {
            margin-bottom: 40px;
            display: flex;
            color: #fff;

            svg {
                color: $cOne;
                font-size: 40px;
            }

            h5 {
                font-size: 25px;
            }

            span {
                font-size: 15px;
            }
        }
    }

    @include mq("phone-wide") {
        &__header {
            .contacts__overlay {
                h1 {
                    font-size: 50px;
                }
            }
        }
    }
}
