@import "../../styles/variables";
@import "../../styles/mixins";

.footer {
    background-color: #222;
    color: #cccccc;
    margin-top: auto;
    padding: 80px 0 10px;

    h3 {
        font-size: 23px;
        color: #fff;
    }

    img {
        width: 170px;
        height: auto;
    }

    &__link {
        display: flex;
        flex-direction: column;

        a {
            margin-top: 9px;

            &:hover {
                color: $cOne;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 20px !important;
    }

    @include mq("tablet") {
        .col-md-4 {
            text-align: center;
            margin-bottom: 30px;

            &::after {
                content: "";
                display: block;
                height: 2px;
                background-color: #dcdcdc;
                width: 60px;
                margin: 25px auto 0;
            }
        }
    }

    @include mq("phone-wide") {
        &__bottom {
            margin-top: 40px !important;
            flex-direction: column;
            align-items: center;
            line-height: 2;
        }
    }
}
