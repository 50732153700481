@import "../../styles/variables";
@import "../../styles/mixins";

.about {
    padding-top: 0;
    overflow: hidden;
    background-color: #000000;

    &__header {
        margin-top: -80px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        height: auto;
        animation: animateabout 12s ease-in-out infinite;

        .about__overlay {
            padding: 150px 0;
            margin-top: 80px;
            background: #0116276b;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                line-height: 1.3;
                max-width: 700px;
                font-size: 55px;
                font-weight: 700;
                color: #fff;
                animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);

                span {
                    margin-right: 12px;
                }
            }

            span {
                display: inline-block;
                opacity: 0;
                filter: blur(4px);
            }

            span:nth-child(1) {
                animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(2) {
                animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(3) {
                animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(4) {
                animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(5) {
                animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(6) {
                animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(7) {
                animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(8) {
                animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(9) {
                animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(10) {
                animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(11) {
                animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(12) {
                animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(13) {
                animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(14) {
                animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(15) {
                animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(16) {
                animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            span:nth-child(17) {
                animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }
    
            span:nth-child(18) {
                animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }
    
            span:nth-child(19) {
                animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }
    
            span:nth-child(20) {
                animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
            }

            p {
                margin-bottom: 40px;
                max-width: 700px;
                color: #fff;
                span {
                    margin-right: 6px;
                }
            }
        }
    }

    &__story {
        &-left {
            background-image: url("../../resources/img/About/3F4A8364.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &-right {
            background-color: $cTwo;

            &-box {
                padding: 50px 10px 50px 30px;

                .about__story-sup {
                    color: $cOne;
                    font-size: 20px;
                }

                .about__story-sub {
                    margin-bottom: 15px;
                    color: #fff;
                    font-size: 30px;
                    line-height: 1.2em;
                }

                p {
                    color: #cccccc;
                }

                ul {
                    list-style-type: none;

                    li {
                        color: #cccccc;

                        svg {
                            color: $cOne;
                        }
                    }
                }
            }
        }
    }

    .about__facilities {
        padding: 40px 0 0;
        background-color: #000000;

        .row {
            margin-bottom: 80px;
        }

        &-img {
            background-image: url("../../resources/img/About/3F4A8405.JPG");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &-text {
            background-color: #000000e0;
            padding: 50px 10px 50px 30px;

            h5 {
                color: $cOne;
                font-size: 18px;
            }

            h4 {
                margin-bottom: 15px;
                color: #fff;
                font-size: 25px;
                line-height: 1.2em;
            }

            p {
                color: #cccccc;
            }
        }
    }

    &__contact {
        background-attachment: fixed;
        background-image: url("../../resources/img/About/3F4A8355.JPG");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &-overlay {
            padding: 120px 0;
            background-color: #0000009d;
        }
    }

    .my-form-info {
        margin-bottom: 40px;
        display: flex;
        color: #fff;

        svg {
            color: $cOne;
            font-size: 40px;
        }

        h5 {
            font-size: 25px;
        }

        span {
            font-size: 15px;
        }
    }

    @keyframes animateabout {
        100%,
        0%,
        18% {
            background-size: 100%;
            background-image: url("../../resources/img/About/DJI_0623.JPG");
        }
        33%,
        48% {
            background-size: 110%;
            background-image: url("../../resources/img/About/dbg2.jpg");
        }
        63%,
        78% {
            background-size: 120%;
            background-image: url("../../resources/img/About/dbg3.jpg");
        }
    }

    @include mq("tablet-mid") {
        @keyframes animateabout {
            100%,
            0%,
            18% {
                background-size: cover;
            }
            33%,
            48% {
                background-size: cover;
            }
            63%,
            78% {
                background-size: cover;
            }
        }
    }
}
