@import "../../styles/variables";
@import "../../styles/mixins";

.tabs__component {
    padding: 80px 0;
    text-align: center;
    a {
        padding-top: 40px;
        font-size: 17px;
        color: $cOne!important;
    }

    h4 {
        color: #ffffff;
        font-weight: 700;
        margin: 10px 0 30px;
        line-height: 1.3em;
        letter-spacing: 1px;
    }

    .MuiButtonBase-root {
        color: #fff;
        font-family: "Mons";
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
    .css-1q2h7u5.Mui-selected,
    .MuiTab-root .MuiTab-textColorPrimary {
        color: #fff;
        font-family: "Mons";
    }

    .MuiTabs-indicator {
        background-color: $cOne;
    }

    img {
        margin-bottom: 20px;
        width: 100%;
        object-fit: cover;
        height: auto;
        cursor: pointer;
    }
}
